<template>
  <div class="modal-backdrop" v-if="this.$store.state.modal.editPromocode">
    <div class="modal-mask" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Редактирование промокода</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-1">
              <label class="col-form-label">Код</label>
              <input type="text" class="form-control" v-model="promocode.code" placeholder="Код">
            </div>
            <div class="form-group mb-1">
              <label class="col-form-label">Описание</label>
              <input type="text" class="form-control" v-model="promocode.desc" placeholder="Описание">
            </div>
            <div class="form-group mb-1">
              <label class="col-form-label">Тип</label>
              <select class="form-control" v-model="promocode.type">
                <option value="0">Процент</option>
                <option value="1">Рубли</option>
                <option value="2">Бесплатная доставка</option>
                <option value="4">Товар в подарок</option>
              </select>
            </div>
            <div class="form-group mb-1" v-if="promocode.type != 2 && promocode.type != 4">
              <label class="col-form-label" v-if="promocode.type == 0">Сумма скидки в %</label>
              <label class="col-form-label" v-if="promocode.type == 1">Сумма скидки в рублях</label>
              <input type="text" class="form-control" v-model="promocode.amount" placeholder="Сумма скидки">
            </div>
            <div class="form-group mb-1">
              <label class="col-form-label">Минимальная сумма заказа</label>
              <input type="text" class="form-control" v-model="promocode.after_amount" placeholder="Минимальная сумма заказа">
            </div>
            <div class="mb-3" v-if="promocode.type == 4">
              <label class="form-label">Выберите товар</label>
              <multiselect v-model="promocode.products" :options="this.productListNoFilter" placeholder="Выберите" label="title" track-by="title" :multiple="true"></multiselect>
            </div>
            <div class="form-group mb-1">
              <label class="col-form-label">Повтор</label>
              <select class="form-control" v-model="promocode.repeat">
                <option value="0">Нет</option>
                <option value="1">Да</option>
              </select>
            </div>
            <div class="form-group mb-1">
              <label class="col-form-label">Регион</label>
              <select class="form-control" v-model="promocode.region">
                <option value="0">Без региона</option>
                <option value="77">Москва</option>
                <option value="78">Санкт-Петербург</option>
              </select>
            </div>
            <div class="form-group mb-1">
              <label class="col-form-label">Дата начала</label>
              <input type="datetime-local" class="form-control" v-model="promocode.date_start" placeholder="Дата начала">
            </div>
            <div class="form-group mb-1">
              <label class="col-form-label">Дата конца</label>
              <input type="datetime-local" class="form-control" v-model="promocode.date_end" placeholder="Дата конца">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Закрыть</button>
            <button type="button" class="btn btn-primary" @click="editPromocode">Сохранить промокод</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect';

export default {
  name: 'edit-promocode',
  props: ['id'],
  data: function(){
    return{
      promocode: {
        id: 0,
        code: '',
        desc: '',
        type: 0,
        amount: 0,
        after_amount: 0,
        repeat: 0,
        region: 0,
        products: [],
        date_start: null,
        date_end: null
      }
    }
  },
  computed: mapGetters(['productListNoFilter', 'promocodeInfo']),
  watch: {
    id: function (val) {
      if(val > 0){
        this.getProductList();
        this.getPromocode();
      }
    },
  },
  methods: {
    getProductList: function(){
      this.$store.dispatch('getProductListNoFilter');
    },
    getPromocode: function(){
      this.$store.dispatch('getPromocode', this.id).then(() => {
        this.promocode.id = this.promocodeInfo.id;
        this.promocode.code = this.promocodeInfo.code;
        this.promocode.desc = this.promocodeInfo.description;
        this.promocode.type = this.promocodeInfo.type;
        this.promocode.amount = this.promocodeInfo.amount;
        this.promocode.after_amount = this.promocodeInfo.after_amount;
        this.promocode.repeat = this.promocodeInfo.repeat;
        this.promocode.region = this.promocodeInfo.region;
        this.promocode.products = this.promocodeInfo.products;
        this.promocode.date_start = this.promocodeInfo.date_start.replace(' ', 'T');
        this.promocode.date_end = this.promocodeInfo.date_end.replace(' ', 'T');
      });
    },
    editPromocode: function(){
      this.$store.dispatch('editPromocode', this.promocode);
    },
    close: function() {
      this.$store.state.modal.editPromocode = false;
    },
  },
  components: {
    Multiselect
  }
};
</script>